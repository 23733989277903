<template>
    <div class="epg-page">
        <EpgContainer :headerContainer="true" :channelCard="true"></EpgContainer>
    </div>
</template>

<script>
export default {
    name: 'Epg',
    components: {
        EpgContainer: () => import(/* webpackChunkName: "epgContainer" */ "@/components/Epg/EpgContainer.vue")
    },
}
</script>

<style lang="scss" scoped>
    @import "@/sass/_variables.scss";
    @import "@/sass/_components.scss";
    @import "./Epg.scss";
</style>